const config = {
  api: "https://int-backend.appines.com",
  branch_url: "https://api2.branch.io/v1/url",
  branch_key: 'key_live_iar7NEPeZmL2UyoK3kLW4adoCvnY2dEy',
  googlemaps_key: "AIzaSyBtmDhVMjjfvFfOSCCr0O_kKg77fVQQ-sA",
  analytics: {
    trackingId: "G-BX4PL6SZBS",
    siteSpeedSampleRate: 100,
  },
  ads: {
    trackingId: "AW-10101010",
    conversion_target: "AW-792685791/x0X0X0X0X0kDEN_Z_fkC"
  },
  bing: {
    conversion_id: "101010101"
  },
  facebook: {
    pixel_id: "101010101"
  },
  sendinblue_url: "https://api.sendinblue.com/v3",
  sendinblue_key: "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",
  sendinblue_list: 25
};

module.exports = config;
